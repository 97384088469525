import { Card, Form } from "react-bootstrap"
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import { Box, Tab } from "@mui/material"
import TabContext from "@mui/lab/TabContext"
import { useEffect, useState } from "react"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import ClickableTable from "../../../components/ClickableTable/ClickableTabl"
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface"
import axios from "axios"
import { getSessionData } from "../../../utils/storageUtils"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading"

function AdministrarTickets() {
    const [guiaAtual, set_guiaAtual] = useState("")
    const [dadosAbertos, setDadosAbertos] = useState([])
    const [dadosPendentes, setDadosPendentes] = useState([])
    const [dadosFechados, setDadosFechados] = useState([])
    const token = getSessionData('MultfilialWebToken') || "";
    const [loading, setLoading] = useState<boolean>(false);
    const [buscarPorFilial, setBuscarPorFilial] = useState(true)
    const [permissao_editar, set_permissao_editar] = useState(true)
    const [idTicket, setIdTicket] = useState("")
    const navigate = useNavigate()

    //#region Controla as guias
    const [guia, setGuia] = useState("1")
    const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
        setGuia(novaGuia);
    };

    const colunas: ITabela[] = [
        { titulo: "ID", acesso: "id_ticket" },
        { titulo: "Titulo", acesso: "titulo" },
        { titulo: "Categoria", acesso: "categoria" },
        { titulo: "Requerente", acesso: "usuario_criacao" },
        { titulo: "Agente", acesso: "agente" },
        { titulo: "Data de Abertura", acesso: "dt_criacao" }
    ]

    function buscarTicketsAbertos() {

        const idFilial = getSessionData("DadosIdfilial")

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/tickets/abertos`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            },
            params: {
                idFilial
            }
        }).then(function (resposta) {
            setLoading(true)
            setDadosAbertos(resposta.data.data)
            setLoading(false)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    function buscarTicketsPendentes() {

        const idFilial = getSessionData("DadosIdfilial")

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/tickets/pendentes`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            },
            params: {
                idFilial
            }
        }).then(function (resposta) {
            setLoading(true)
            setDadosPendentes(resposta.data.data)
            setLoading(false)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    function buscarTicketsFechados() {

        const idFilial = getSessionData("DadosIdfilial")

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/tickets/fechados`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            },
            params: {
                idFilial
            }
        }).then(function (resposta) {
            setLoading(true)
            setDadosFechados(resposta.data.data)
            setLoading(false)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    useEffect(() => {
        buscarTicketsAbertos()
        buscarTicketsPendentes()
        buscarTicketsFechados()
    }, [])

    function irParaDetalhes(dados: any) {
        navigate(`detalhesTicket/${dados.id_ticket}`)
    }

    function teste() {

    }

    return (
        <>
            <div className="col">
                <ContainerTitulo titulo="Administração dos Tickets" />

                <Card>
                    <Form onSubmit={teste}>
                        <Box sx={{ width: "100%" }}>
                            <TabContext value={guia}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <TabList
                                        onChange={capturaGuia}
                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: "#000",
                                                color: "#000",
                                            },
                                        }}
                                    >
                                        <Tab onClick={function () {
                                            set_guiaAtual("")
                                        }} label="Tickets Abertos" value="1" />

                                        <Tab onClick={function () {
                                            set_guiaAtual("")
                                        }} label="Tickets Pendentes" value="2" />
                                        <Tab onClick={function () {

                                            set_guiaAtual("")
                                        }} label="Tickets Finalizados" value="3" />
                                    </TabList>
                                    <TabPanel value="1">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <ClickableTable
                                                    coluna={colunas}
                                                    data={dadosAbertos}
                                                    usaRowClick={true}
                                                    onRowClick={irParaDetalhes}
                                                    itemsPerPage={10}
                                                    acaoVisualizar={teste}
                                                    acaoEditar={irParaDetalhes}
                                                    acaoExcluir={teste}
                                                    usaAcoes={false}
                                                    usaEditar={permissao_editar}
                                                    //usaVisualizar={permissao_visualizar}
                                                    //usaExcluir={permissao_excluir}
                                                    labelpesquisa="Buscar por Titulo"
                                                    acessopesquisa="titulo"
                                                />
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <ClickableTable
                                                    coluna={colunas}
                                                    data={dadosPendentes}
                                                    usaRowClick={true}
                                                    onRowClick={irParaDetalhes}
                                                    itemsPerPage={10}
                                                    acaoVisualizar={teste}
                                                    acaoEditar={irParaDetalhes}
                                                    acaoExcluir={teste}
                                                    usaAcoes={false}
                                                    usaEditar={permissao_editar}
                                                    //usaVisualizar={permissao_visualizar}
                                                    //usaExcluir={permissao_excluir}
                                                    labelpesquisa="Buscar por Titulo"
                                                    acessopesquisa="titulo"
                                                />
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="3">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <ClickableTable
                                                    coluna={colunas}
                                                    data={dadosFechados}
                                                    usaRowClick={true}
                                                    onRowClick={irParaDetalhes}
                                                    itemsPerPage={10}
                                                    acaoVisualizar={teste}
                                                    acaoEditar={irParaDetalhes}
                                                    acaoExcluir={teste}
                                                    usaAcoes={false}
                                                    usaEditar={permissao_editar}
                                                    //usaVisualizar={permissao_visualizar}
                                                    //usaExcluir={permissao_excluir}
                                                    labelpesquisa="Buscar por Titulo"
                                                    acessopesquisa="titulo"
                                                />
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Box>
                            </TabContext>
                        </Box>
                    </Form>
                </Card>
                <ModalLoading show={loading} label="Carregando..." />
            </div>
        </>
    )
}

export default AdministrarTickets