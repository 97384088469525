import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from "../../../components/Formularios/Buttons/Button"
import { mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import { Col, Row } from 'react-bootstrap';
import InputSemBorda from '../Inputs/InputsSemBorda';
import axios from 'axios';
import { getSessionData } from '../../../utils/storageUtils';
import { toast } from 'react-toastify';

interface tiposModalEditarCampos {
    id_Campo: string,
    label_Campo: string,
    tipo_Campo: string,
    tabela_Selecionada: string,
    obrigatorio_: boolean,
    id_det?: number,
    onClick: (idCampo: string, tipoCampo: string, tabelaSelecionada: string, labelCampo: string, obrigatorio: boolean, id_det: number | undefined) => void
}

function ModalEditarCampos({
    id_Campo,
    label_Campo,
    tipo_Campo,
    tabela_Selecionada,
    obrigatorio_,
    id_det,
    onClick
}: tiposModalEditarCampos) {
    const [show, setShow] = useState(false);
    const [idCampo, setIdCampo] = useState(id_Campo)
    const [labelCampo, setLabelCampo] = useState(label_Campo)
    const [tipoCampo, setTipoCampo] = useState(tipo_Campo)
    const [tabela, setTabela] = useState([])
    const [tabelaSelecionada, setTabelaSelecionada] = useState(tabela_Selecionada)
    const [obrigatorio, setObrigatorio] = useState(obrigatorio_)
    const token = getSessionData('MultfilialWebToken') || "";

    const handleClose = function () {
        setShow(false)
    };

    const handleShow = () => setShow(true);

    // Função assíncrona para preencher os campos dos select boxes com dados da API
    async function preencherSelectBox() {
        // Faz uma requisição GET para obter dados de vários select boxes da API
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/preencher/selectbox/formularios`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            }
        }).then(function (resposta) {
            // Atualiza o estado com os dados recebidos da API
            setTabela(resposta.data.dataTabelas)
        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    useEffect(() => {
        preencherSelectBox()
    }, [])


    return (
        <>
            <button className="btn-sm btn-dark text-info-emphasis bg-primary border-0 rounded ms-1 me-1"
                onClick={handleShow}>
                <i className="mdi mdi-pencil"></i>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size='lg'
                centered
            >
                <Modal.Header closeButton className='bg-white'>
                    <Modal.Title>Editar Campo</Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-white'>
                    <Row>
                        <Col>
                            <InputSemBorda
                                label="ID do Campo"
                                name="idCampo"
                                readonly
                                type="text"
                                placeholder="ID do Campo"
                                value={idCampo}
                                onChange={setIdCampo}
                                classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                            />
                        </Col>
                        <Col>
                            <div className="col-16 form-floating">
                                <select
                                    autoFocus
                                    required
                                    className="form-select bordasInferiorInput text-black text-capitalize"
                                    onChange={(event) => setTipoCampo(event.target.value)}
                                    value={tipoCampo}
                                >
                                    <option value={""}>Selecione...</option>
                                    <option value={"1"}>Text</option>
                                    <option value={"2"}>Number</option>
                                    <option value={"3"}>Select</option>
                                </select>
                                <label className="form-label">Tipo do Campo</label>
                            </div>
                        </Col>
                        <Col>
                            <InputSemBorda
                                label="Label do Campo"
                                name="labelCampo"
                                type="text"
                                placeholder="Label do Campo"
                                value={labelCampo}
                                onChange={setLabelCampo}
                                classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                            />
                        </Col>
                        <Col>
                            <div className="form-check mt-3">
                                <input className="form-check-input" type="checkbox" onChange={function (e) {
                                    setObrigatorio(e.target.checked)
                                }} checked={obrigatorio} value="" id="flexCheckDefault" />
                                <label className="form-check-label ms-1 mt-1" htmlFor="flexCheckDefault">
                                    É obrigatório?
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4} hidden={tipoCampo !== '3'}>
                            <div className="col-16 form-floating">
                                <select
                                    autoFocus
                                    required
                                    className="form-select bordasInferiorInput text-black text-capitalize"
                                    onChange={(event) => setTabelaSelecionada(event.target.value)}
                                    value={tabelaSelecionada}

                                >
                                    <option value={""}>Selecione...</option>
                                    {tabela.map((item: any) => (
                                        <option value={item.tabela}>{item.tabela}</option>
                                    ))}
                                </select>
                                <label className="form-label">Tabela de Respostas</label>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='bg-white'>
                    <Button
                        invisivel={false}
                        type="submit"
                        className="btn btn-dark text-info-emphasis bg-primary border-0 rounded"
                        descricaoBotao="Salvar"
                        onclick={function () {
                            onClick(idCampo, tipoCampo, tabelaSelecionada, labelCampo, obrigatorio, id_det)
                            handleClose()
                        }}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalEditarCampos;