import { Card, Col, Row } from "react-bootstrap"
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import { useEffect, useState } from "react"
import { Button } from "../../../components/Formularios/Buttons/Button"
import { mdiCheckAll } from "@mdi/js"
import axios from "axios"
import { toast } from "react-toastify"
import { getSessionData } from "../../../utils/storageUtils"
import ModalIncluirCampos from "../../../components/Formularios/FomulariosIncluir/AdicionarCampos"
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import ModalEditarCampos from "../../../components/Formularios/FormulariosEditar/EditarCampos"
import { useNavigate } from "react-router-dom"

function FormulariosIncluir() {
    const [sistema, setSistema] = useState([])
    const [categoria, setCategoria] = useState([])
    const [listaInputsForm, setListaInputsForm] = useState<any>([])
    const [sistemaSelecionado, setSistemaSelecionado] = useState('')
    const [categoriaSelecionada, setCategoriaSelecionada] = useState('')
    const [listaCodSist, setListaCodSist] = useState([])
    const [loading, setLoading] = useState<boolean>(false);
    const token = getSessionData('MultfilialWebToken') || "";
    const navigate = useNavigate()

    function adicionarInputForm(idCampo: string, tipoCampo: string, tabelaSelecionada: string, labelCampo: string, obrigatorio: boolean) {
        setListaInputsForm([...listaInputsForm, {
            idCampo: idCampo,
            tipoCampo: tipoCampo,
            tabelaSelecionada: tabelaSelecionada,
            labelCampo: labelCampo,
            obrigatorio: obrigatorio
        }])
    }

    function editarInputForm(idCampo: string, tipoCampo: string, tabelaSelecionada: string, labelCampo: string, obrigatorio: boolean) {
        const novaLista = listaInputsForm.map(function (input: any) {
            if (input.idCampo === idCampo) {
                // Retorna um novo objeto com as propriedades atualizadas
                return {
                    ...input,
                    tipoCampo,
                    tabelaSelecionada,
                    labelCampo,
                    obrigatorio,
                };
            }
            return input;
        })

        // Atualiza o estado com a nova lista
        setListaInputsForm(novaLista);
    }


    useEffect(() => {
        /// console.log(listaInputsForm)
    }, [listaInputsForm])


    function preencherListaCodSist() {
        const idFilial = getSessionData("DadosIdfilial")

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/preencher/lista/codsist/${idFilial}`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            }
        }).then(function (resposta) {
            setListaCodSist(resposta.data.dataListaCodSist)
        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    useEffect(() => {
        preencherSelectBox()
        preencherListaCodSist()
    }, [])

    function enviarDados(e: any) {
        e.preventDefault()

        const idFilial = getSessionData("DadosIdfilial")
        const idUsuario = getSessionData("DadosUsuarioID")

        const dados = {
            sistemaSelecionado,
            categoriaSelecionada,
            idFilial,
            idUsuario,
            listaInputsForm
        }

        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/enviar/cabecalho/formularios`, dados, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            setLoading(false)
            setTimeout(() => {
                navigate(-1);
            }, 2000);
        }).catch(function (erro) {
            setLoading(false)
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            } else if (status == 500) {
                toast.error(erro.response.data.message)
            }
        })
    }

    const removeInput = (idCampo: string) => {

        showConfirmationDialog("Remover campo?", "Esta ação irá remover o campo selecionado",
            "warning", 0).then(function (confirmed) {
                if (confirmed.confirmed) {
                    const updatedInputs = listaInputsForm.filter(function (input: any) {
                        return input.idCampo !== idCampo
                    });
                    setListaInputsForm(updatedInputs);
                }
            })
    };
    // Função assíncrona para preencher os campos dos select boxes com dados da API
    function preencherSelectBox() {
        const idFilial = getSessionData("DadosIdfilial")

        // Faz uma requisição GET para obter dados de vários select boxes da API
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/preencher/selectbox/formularios/${idFilial}`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            }
        }).then(function (resposta) {
            // Atualiza o estado com os dados recebidos da API
            setSistema(resposta.data.dataSistemas)
            //setCategoria(resposta.data.dataCategorias)
        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }
    function CarregarCategoriasSistema(sistemaSelecionado: string) {
        setLoading(true)
        const id_filial = sessionStorage.getItem("DadosIdfilial")!
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/categorias/sistema/form/${id_filial}/${sistemaSelecionado}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setCategoria(resposta.data.categorias)
            setLoading(false)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            setLoading(false)
        })
    }
    useEffect(function () {
        //carrega as categorias do sistema selecionado
        if (sistemaSelecionado !== "") {
            CarregarCategoriasSistema(sistemaSelecionado)
        }
    }, [sistemaSelecionado])
    return (
        <>
            <div className="col">
                <ContainerTitulo titulo="Inclusão de Formulário" />

                <form onSubmit={enviarDados}>
                    <Card className="p-4">
                        <Card className="mb-4 p-3 border shadow">
                            <Row>
                                <Col sm={3} className="mb-3">
                                    <div className="col-16 form-floating">
                                        <select
                                            autoFocus
                                            disabled={listaInputsForm.length > 0}
                                            required
                                            className="form-select bordasInferiorInput text-black text-capitalize"
                                            onChange={(event) => setSistemaSelecionado(event.target.value)}
                                            value={sistemaSelecionado}
                                        >
                                            <option value={""}>Selecione...</option>
                                            {sistema.map((item: any) => (
                                                <option value={item.descricao}>{item.descricao}</option>
                                            ))}
                                        </select>
                                        <label className="form-label">Sistema</label>
                                    </div>
                                </Col>
                                <Col sm={3} className="mb-3">
                                    <div className="col-16 form-floating">
                                        <select
                                            autoFocus
                                            disabled={listaInputsForm.length > 0 || sistemaSelecionado == ""}
                                            required
                                            className="form-select bordasInferiorInput text-black text-capitalize"
                                            onChange={(event) => setCategoriaSelecionada(event.target.value)}
                                            value={categoriaSelecionada}
                                        >
                                            <option value={""}>Selecione...</option>
                                            {categoria.map((item: any) => (
                                                <option value={item.descricao}>{item.descricao}</option>
                                            ))}
                                        </select>
                                        <label className="form-label">Categoria</label>
                                    </div>
                                </Col>
                                <Col sm={2} className="mb-3">
                                    <div className="mt-2">
                                        <ModalIncluirCampos onClick={adicionarInputForm} />
                                    </div>
                                </Col>
                                <Col sm={2} className="mb-3">
                                    <div className="mt-2">
                                        <Button
                                            invisivel={listaInputsForm.length == 0}
                                            className="btn btn-dark text-info-emphasis bg-primary border-0 rounded w-100"
                                            descricaoBotao="Salvar"
                                            icone={mdiCheckAll}
                                            type='submit'
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Card>
                </form>
                <Card className="p-4">
                    <Card className="mb-4 p-3 border shadow">
                        <h4>Simulando formulário</h4>
                        <h6 hidden={listaInputsForm.length > 0}>Ainda não foi adicionado nenhum campo</h6>
                        <form onSubmit={function (e) {
                            e.preventDefault()
                        }}>
                            <Row>
                                {
                                    listaInputsForm.map(function (input: any) {
                                        const tabela: any = listaCodSist[input.tabelaSelecionada]
                                        return input.tipoCampo == "3" ?
                                            <Col sm={3}>
                                                <div className="d-flex align-items-center">
                                                    <div className="col-16 form-floating">
                                                        <select
                                                            autoFocus
                                                            required={input.obrigatorio == true ? true : false}
                                                            className="form-select bordasInferiorInput text-black text-capitalize"
                                                            id={input.idCampo}
                                                        >
                                                            <option value={""}>Selecione...</option>
                                                            {
                                                                tabela.map(function (option: any) {
                                                                    return <option value={option.codigo}>{option.descricao}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <ModalEditarCampos
                                                        id_Campo={input.idCampo}
                                                        label_Campo={input.labelCampo}
                                                        tipo_Campo={input.tipoCampo}
                                                        tabela_Selecionada={input.tabelaSelecionada}
                                                        obrigatorio_={input.obrigatorio}
                                                        onClick={editarInputForm}
                                                    />
                                                    <button className="btn-sm btn-dark text-info-emphasis bg-primary border-0 rounded" type="button"
                                                        onClick={() => removeInput(input.idCampo)}>
                                                        <i className="mdi mdi-delete"></i>
                                                    </button>
                                                </div>
                                            </Col>
                                            :
                                            input.tipoCampo == "2" ?
                                                <Col sm={3}>
                                                    <div className="d-flex align-items-center">
                                                        <div className="form-floating">
                                                            <input
                                                                required={input.obrigatorio == true ? true : false}
                                                                id={input.idCampo}
                                                                className="form-control bordasInferiorInput text-black text-capitalize"
                                                                placeholder=" "
                                                                type="number" />
                                                            <label>{input.labelCampo}</label>
                                                        </div>
                                                        <ModalEditarCampos
                                                            id_Campo={input.idCampo}
                                                            label_Campo={input.labelCampo}
                                                            tipo_Campo={input.tipoCampo}
                                                            tabela_Selecionada={input.tabelaSelecionada}
                                                            obrigatorio_={input.obrigatorio}
                                                            onClick={editarInputForm}
                                                        />
                                                        <button className="btn-sm btn-dark text-info-emphasis bg-primary border-0 rounded"
                                                            onClick={() => removeInput(input.idCampo)}>
                                                            <i className="mdi mdi-delete"></i>
                                                        </button>
                                                    </div>
                                                </Col>
                                                :
                                                input.tipoCampo == "1" ?
                                                    <Col sm={3}>
                                                        <div className="d-flex align-items-center">
                                                            <div className="form-floating">
                                                                <input
                                                                    required={input.obrigatorio == true ? true : false}
                                                                    id={input.idCampo}
                                                                    className="form-control bordasInferiorInput text-black text-capitalize"
                                                                    placeholder=" "
                                                                    type="text" />
                                                                <label>{input.labelCampo}</label>
                                                            </div>
                                                            <ModalEditarCampos
                                                                id_Campo={input.idCampo}
                                                                label_Campo={input.labelCampo}
                                                                tipo_Campo={input.tipoCampo}
                                                                tabela_Selecionada={input.tabelaSelecionada}
                                                                obrigatorio_={input.obrigatorio}
                                                                onClick={editarInputForm}
                                                            />
                                                            <button className="btn-sm btn-dark text-info-emphasis bg-primary border-0 rounded"
                                                                onClick={() => removeInput(input.idCampo)}>
                                                                <i className="mdi mdi-delete"></i>
                                                            </button>
                                                        </div>
                                                    </Col>
                                                    :
                                                    <></>
                                    })
                                }
                            </Row>
                        </form>
                    </Card>
                </Card>
            </div>
        </>
    )
}

export default FormulariosIncluir