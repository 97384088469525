import { Card, Col, Row } from "react-bootstrap"
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import { useEffect, useState } from "react"
import { Button } from "../../../components/Formularios/Buttons/Button"
import { mdiCheckAll } from "@mdi/js"
import axios from "axios"
import { toast } from "react-toastify"
import { getSessionData } from "../../../utils/storageUtils"
import ModalIncluirCampos from "../../../components/Formularios/FomulariosIncluir/AdicionarCampos"
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import ModalEditarCampos from "../../../components/Formularios/FormulariosEditar/EditarCampos"
import { useParams } from "react-router-dom"
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda"
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading"

function FormulariosEditar() {
    const [sistema, setSistema] = useState('')
    const [categoria, setCategoria] = useState('')
    const [listaInputsForm, setListaInputsForm] = useState<any>([])
    const [listaCodSist, setListaCodSist] = useState([])
    const { idFormulario } = useParams();
    const [dados, setDados] = useState([])
    const [loading, setLoading] = useState<boolean>(false);
    const token = getSessionData('MultfilialWebToken') || "";

    function teste() {

    }

    function adicionarInputForm(idCampo: string, tipoCampo: string, tabelaSelecionada: string, labelCampo: string, obrigatorio: boolean) {
        if (idFormulario) {

            const idUsuario = getSessionData("DadosUsuarioID")
            const idFilial = getSessionData("DadosIdfilial")

            const dados = {
                idFormulario,
                idCampo,
                tipoCampo,
                labelCampo,
                obrigatorio,
                tabelaSelecionada,
                idUsuario,
                idFilial
            }

            setLoading(true)
            axios.post(`${process.env.REACT_APP_API_BASE_URL}/inserir/input/formulario`, dados, {
                headers: {
                    Authorization: token
                }
            }).then(function (resposta) {
                toast.success(resposta.data.message)
                buscarDetalhes()
                setLoading(false)
            }).catch(function (erro) {
                setLoading(false)
                const status = erro.response.status
                if (status == 403 || status == 401) {
                    toast.error("Acesso negado, faça seu login novamente")
                }
            })
        } else {
            setListaInputsForm([...listaInputsForm, {
                idCampo: idCampo,
                tipoCampo: tipoCampo,
                tabelaSelecionada: tabelaSelecionada,
                labelCampo: labelCampo,
                obrigatorio: obrigatorio
            }])
        }
    }

    function editarInputForm(idCampo: string, tipoCampo: string, tabelaSelecionada: string, labelCampo: string, obrigatorio: boolean, id_det: number | undefined) {

        const idUsuario = getSessionData("DadosUsuarioID")
        const idFilial = getSessionData("DadosIdfilial")

        const dados = {
            idFormulario,
            id_det,
            tipoCampo,
            labelCampo,
            obrigatorio,
            tabelaSelecionada,
            idUsuario,
            idFilial
        }

        setLoading(true)
        axios.put(`${process.env.REACT_APP_API_BASE_URL}/alterar/input/formulario`, dados, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            buscarDetalhes()
            setLoading(false)
        }).catch(function (erro) {
            setLoading(false)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    function buscarCabecalho() {
        const idFilial = getSessionData("DadosIdfilial")

        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/visualizar/cabecalho/formulario/${idFormulario}/${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setCategoria(resposta.data.data[0].categoria)
            setSistema(resposta.data.data[0].sistema)
            setLoading(false)
        }).catch(function (erro) {
            setLoading(false)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    function buscarDetalhes() {
        const idFilial = getSessionData("DadosIdfilial")

        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/visualizar/detalhes/formulario/${idFormulario}/${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDados(resposta.data.data)
            setLoading(false)
        }).catch(function (erro) {
            setLoading(false)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    useEffect(() => {
        buscarCabecalho()
        buscarDetalhes()
    }, [])

    function removerInput(obj: any) {
        const idFilial = getSessionData("DadosIdfilial")

        showConfirmationDialog("Remover campo?", "Esta ação irá excluir o campo selecionado",
            "warning", 0).then(function (confirmed) {
                if (confirmed.confirmed) {
                    setLoading(true)
                    axios.delete(`${process.env.REACT_APP_API_BASE_URL}/remover/input/formulario/${idFormulario}/${obj.id_det}/${idFilial}`, {
                        headers: {
                            Authorization: token
                        }
                    }).then(function (resposta) {
                        toast.success(resposta.data.message)
                        buscarDetalhes()
                        setLoading(false)
                    }).catch(function (erro) {
                        setLoading(false)
                        // Trata erros de autorização e exibe uma mensagem para o usuário
                        const status = erro.response.status
                        if (status == 403 || status == 401) {
                            toast.error("Acesso negado, faça seu login novamente")
                        } else if (status == 500) {
                            toast.error(erro.response.data.message)
                        }
                    })
                }
            })
    };

    return (
        <>
            <div className="col">
                <ContainerTitulo titulo="Edição - Detalhes do Formulário" />

                <form onSubmit={teste}>
                    <Card className="p-4">
                        <Card className="mb-4 p-3 border shadow">
                            <Row>
                                <Col sm={6} className="mb-3">
                                    <div className="col-16 form-floating">
                                        <InputSemBorda
                                            label="Sistema"
                                            name="sistema"
                                            type="text"
                                            readonly
                                            placeholder="Sistema"
                                            value={sistema}
                                            onChange={setSistema}
                                            classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                        />
                                    </div>
                                </Col>
                                <Col sm={6} className="mb-3">
                                    <div className="col-16 form-floating">
                                        <InputSemBorda
                                            label="Categoria"
                                            name="categoria"
                                            type="text"
                                            readonly
                                            placeholder="Categoria"
                                            value={categoria}
                                            onChange={setCategoria}
                                            classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                        <div className="d-flex justify-content-end">
                            <div className="w-25">
                                <ModalIncluirCampos onClick={adicionarInputForm} />
                            </div>
                        </div>
                    </Card>
                </form>
                <Card className="p-4">
                    <Card className="mb-4 p-3 border shadow">
                        <h4>Campos adicionados ao formulário</h4>
                        <form onSubmit={function (e) {
                            e.preventDefault()
                        }}>
                            <Row>
                                {dados.map((campo: any) => {
                                    return (
                                        <>
                                            {campo.tipo_campo === '3' && (
                                                <Col sm={3} key={campo.id_det}>
                                                    <div className="d-flex align-items-center">
                                                        <div className="col-16 form-floating">
                                                            <select
                                                                autoFocus
                                                                disabled
                                                                className="form-select bordasInferiorInput text-black text-capitalize"
                                                                id={campo.id_campo}
                                                            >
                                                                <option value={""}>{campo.tabela}</option>
                                                            </select>
                                                        </div>
                                                        <ModalEditarCampos
                                                            id_Campo={campo.id_campo}
                                                            label_Campo={campo.label_campo}
                                                            tipo_Campo={campo.tipo_campo}
                                                            tabela_Selecionada={campo.tabela}
                                                            obrigatorio_={campo.obrigatorio}
                                                            id_det={campo.id_det}
                                                            onClick={editarInputForm}
                                                        />
                                                        <button className="btn-sm btn-dark text-info-emphasis bg-primary border-0 rounded" type="button"
                                                            onClick={function () {
                                                                removerInput(campo)
                                                            }}>
                                                            <i className="mdi mdi-delete"></i>
                                                        </button>
                                                    </div>
                                                </Col>
                                            )}

                                            {campo.tipo_campo === '2' && (
                                                <Col sm={3} key={campo.id_det}>
                                                    <div className="d-flex align-items-center">
                                                        <div className="form-floating">
                                                            <input
                                                                id={campo.id_campo}
                                                                readOnly
                                                                className="form-control bordasInferiorInput text-black text-capitalize"
                                                                placeholder=" "
                                                                type="number"
                                                            />
                                                            <label>{campo.label_campo}</label>
                                                        </div>
                                                        <ModalEditarCampos
                                                            id_Campo={campo.id_campo}
                                                            label_Campo={campo.label_campo}
                                                            tipo_Campo={campo.tipo_campo}
                                                            tabela_Selecionada={campo.tabela}
                                                            obrigatorio_={campo.obrigatorio}
                                                            id_det={campo.id_det}
                                                            onClick={editarInputForm}
                                                        />
                                                        <button className="btn-sm btn-dark text-info-emphasis bg-primary border-0 rounded" type="button"
                                                            onClick={function () {
                                                                removerInput(campo)
                                                            }}>
                                                            <i className="mdi mdi-delete"></i>
                                                        </button>
                                                    </div>
                                                </Col>
                                            )}

                                            {campo.tipo_campo === '1' && (
                                                <Col sm={3} key={campo.id_det}>
                                                    <div className="d-flex align-items-center">
                                                        <div className="form-floating">
                                                            <input
                                                                id={campo.id_campo}
                                                                readOnly
                                                                className="form-control bordasInferiorInput text-black text-capitalize"
                                                                placeholder=" "
                                                                type="text"
                                                            />
                                                            <label>{campo.label_campo}</label>
                                                        </div>
                                                        <ModalEditarCampos
                                                            id_Campo={campo.id_campo}
                                                            label_Campo={campo.label_campo}
                                                            tipo_Campo={campo.tipo_campo}
                                                            tabela_Selecionada={campo.tabela}
                                                            obrigatorio_={campo.obrigatorio}
                                                            id_det={campo.id_det}
                                                            onClick={editarInputForm}
                                                        />
                                                        <button className="btn-sm btn-dark text-info-emphasis bg-primary border-0 rounded" type="button"
                                                            onClick={function () {
                                                                removerInput(campo)
                                                            }}>
                                                            <i className="mdi mdi-delete"></i>
                                                        </button>
                                                    </div>
                                                </Col>
                                            )}
                                        </>
                                    );
                                })}
                            </Row>

                        </form>
                    </Card>
                </Card>
            </div>
            <ModalLoading show={loading} label="Carregando Listagem de Formulários" />
        </>
    )
}

export default FormulariosEditar