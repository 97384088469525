import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { Tab, Box, Switch } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import axios from 'axios';
import { mdiCheckCircle, mdiKeyboardReturn, mdiSendCircle } from "@mdi/js";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import { Button } from "../../../components/Formularios/Buttons/Button";
import { getSessionData } from "../../../utils/storageUtils";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { mascaraTelefoneCelular } from "../../../hooks/mascaras";
import { mascaraTelefoneFixo } from "../../../hooks/mascaras";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import ModalEditarCampos from "../../../components/Formularios/FormulariosEditar/EditarCampos";
import DetalhesTicket from "../controleTickets/detalhesTicket";

function CriarTicket() {
    const [nomeUsuario, setNomeUsuario] = useState<string>("");
    const [emailUsuario, setEmailUsuario] = useState<string>("");
    const [telefoneUsuario, setTelefoneUsuario] = useState<string>("");
    const [empresa, setEmpresa] = useState<string>("");
    const [categoria, setCategoria] = useState([]);
    const [sistema, setSistema] = useState([]);
    const [tituloTicket, setTituloTicket] = useState<string>("")
    const [descricaoTicket, setDescricaoTicket] = useState<string>("")
    const [sistemaSelecionado, setSistemaSelecionado] = useState<string>("");
    const [categoriaSelecionada, setCategoriaSelecionada] = useState<string>("");
    const [controleReadonlyTelefone, setControleReadonlyTelefone] = useState(true);
    const [idFilial, setIdFilial] = useState<string>("");
    const [idUsuarioCriacao, setIdUsuarioCriacao] = useState<string>("");
    const [arquivoConvertido, setArquivoConvertido] = useState('');
    const [nomeArquivo, setNomeArquivo] = useState<string>("");
    const [tipoArquivo, setTipoArquivo] = useState<string>("");
    const [tipoCadastro, setTipoCadastro] = useState([]);
    const [tipoCadastroSelecionado, setTipoCadastroSelecionado] = useState<string>("");
    const [referencia, setReferencia] = useState<string>("");
    const [tipoProcesso, setTipoProcesso] = useState([]);
    const [tipoProcessoSelecionado, setTipoProcessoSelecionado] = useState<string>("");
    const [tiposPedido, setTiposPedido] = useState([]);
    const [tiposPedidoSelecionado, setTiposPedidoSelecionado] = useState<string>("");
    const [servicos, setServicos] = useState([]);
    const [servicoSelecionado, setServicoSelecionado] = useState<string>("");
    const [etapaNFE, setEtapaNFE] = useState([]);
    const [etapaNFESelecionada, setEtapaNFESelecionada] = useState<string>("");
    const [depositante, setDepositante] = useState<string>("");
    const navigate = useNavigate()
    const [listaArquivos, setListaArquivos] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [dadosDetalhes, setDadosDetalhes] = useState<any>([])
    const [listaCodSist, setListaCodSist] = useState([])
    const token = getSessionData('MultfilialWebToken') || "";

    // Função assíncrona para preencher os campos dos select boxes com dados da API
    async function preencherSelectBox() {
        // Faz uma requisição GET para obter dados de vários select boxes da API
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/preencher/selectbox`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            },
            params: {
                sistemaSelecionado
            }
        }).then(function (resposta) {
            // Atualiza o estado com os dados recebidos da API
            setSistema(resposta.data.dataSistemas)
            setCategoria(resposta.data.dataCategorias)
            setTipoCadastro(resposta.data.dataTipoCadastros)
            setTipoProcesso(resposta.data.dataTiposProcesso)
            setEtapaNFE(resposta.data.dataEtapaNFE)
            setTiposPedido(resposta.data.dataTiposPedido)
            setServicos(resposta.data.dataServicos)
        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    // Hook useEffect para executar a função preencherSelectBox e carregarDados quando o componente é montado
    useEffect(() => {
        preencherListaCodSist()
        carregarDados() // Carrega os dados do usuário da API
    }, []) // Dependência vazia significa que o useEffect será chamado apenas uma vez após a montagem do componente

    useEffect(() => {
        preencherSelectBox() // Preenche os select boxes com dados da API
    }, [sistemaSelecionado])

    // Função para converter um arquivo em uma string base64 quando um arquivo é selecionado
    const converterAnexo = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file: any = event.target.files; // Obtém os arquivos selecionados

        const objListaArquivos: any = [] // Cria uma lista para armazenar os arquivos convertidos

        for (let i = 0; i < file.length; i = i + 1) {
            const arquivo = file[i]
            const objArquivo = {
                nomeArquivo: arquivo.name, // Nome do arquivo
                tipoArquivo: arquivo.type, // Tipo do arquivo
                base64: '' // Inicializa o valor base64 como uma string vazia
            }

            if (arquivo) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    if (reader.result) {
                        // Garante que reader.result seja tratado como uma string
                        const base64String = reader.result as string;
                        objArquivo.base64 = base64String // Armazena o valor base64 no objeto do arquivo
                        objListaArquivos.push(objArquivo) // Adiciona o arquivo convertido à lista
                    }
                };

                reader.readAsDataURL(arquivo); // Lê o arquivo como uma URL de dados base64
            }
        }
        setListaArquivos(objListaArquivos) // Atualiza o estado com a lista de arquivos convertidos
    };

    // Função para carregar os dados do usuário a partir da API
    function carregarDados() {
        const idUsuario = sessionStorage.getItem('DadosUsuarioID') // Obtém o ID do usuário do sessionStorage

        // Faz uma requisição GET para obter os dados do usuário da API
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/usuario/carregar/${idUsuario}`, {
            headers: { Authorization: token } // Adiciona o token de autorização no cabeçalho da requisição
        }).then(function (resposta) {
            setLoading(true); // Define o estado de carregamento como falso
            // Atualiza o estado com os dados recebidos da API
            setIdFilial(resposta.data[0].filial[0].id_filial)
            setIdUsuarioCriacao(resposta.data[0].id_usuario)
            setNomeUsuario(resposta.data[0].nome)
            setEmailUsuario(resposta.data[0].email)
            setTelefoneUsuario(resposta.data[0].telefone)
            setLoading(false); // Define o estado de carregamento como verdadeiro

            // Define se o telefone pode ser editado com base em seu valor
            if (resposta.data[0].telefone == '') {
                setControleReadonlyTelefone(false) // Permite a edição se o telefone estiver vazio
            }

            mascararTelefones() // Aplica a máscara no telefone do usuário
            setEmpresa(resposta.data[0].filial[0].fantasia) // Define o nome da empresa
        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    // Função para enviar os dados do formulário para a API
    function enviarDados(e: any) {
        e.preventDefault() // Previne o comportamento padrão de envio do formulário
        //looping para pegar os valores dos campos dinamicos
        const valoresDadosDetalhes = []
        for (let i = 0; i < dadosDetalhes.length; i = i + 1) {
            const idAcesso = dadosDetalhes[i].id_campo
            const campo: any = document.getElementById(idAcesso)
            const labelCampo: any = document.getElementById('label' + idAcesso)
            if (campo) {
                valoresDadosDetalhes.push({
                    id_campo: dadosDetalhes[i].id_campo,
                    value: campo.value,
                    tipo: campo.tagName,
                    label: labelCampo.textContent
                })
            }
        }
        const dados = {
            nomeUsuario,
            sistemaSelecionado,
            categoriaSelecionada,
            tituloTicket,
            descricaoTicket,
            idFilial,
            idUsuarioCriacao,
            listaArquivos,
            tipoCadastroSelecionado,
            referencia,
            tipoProcessoSelecionado,
            etapaNFESelecionada,
            depositante,
            tiposPedidoSelecionado,
            servicoSelecionado,
            empresa,
            telefoneUsuario,
            emailUsuario: emailUsuario,
            detalhesCampos: dadosDetalhes, //guarda o nome dos campos dinamicos a serem acessados
            valoresDadosDetalhes: valoresDadosDetalhes
        }
        // Verifica se a descrição do ticket foi preenchida
        if (descricaoTicket == '') {
            toast.info('O ticket não pode ser criado sem uma descrição') // Exibe uma mensagem informativa
            return
        }
        setLoading(true); // Define o estado de carregamento como verdadeiro
        // Faz uma requisição POST para enviar os dados do ticket para a API
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/enviar/dadosTicket`, dados, {
            headers: { Authorization: token } // Adiciona o token de autorização no cabeçalho da requisição
        }).then(function (resposta) {
            toast.success(resposta.data.message) // Exibe uma mensagem de sucesso
            setTimeout(() => {
                navigate(-1) // Redireciona o usuário para a página anterior após 2 segundos
            }, 2000);
            setLoading(false)
        }).catch(function (erro) {
            setLoading(false)
            toast.error(erro.response.data.message) // Exibe uma mensagem de erro
        })
    }

    // Função para aplicar uma máscara de telefone no valor do telefone do usuário
    function mascararTelefones() {
        if (telefoneUsuario.length == 11) {
            setTelefoneUsuario(mascaraTelefoneCelular(telefoneUsuario)) // Aplica a máscara de celular
        } else if (telefoneUsuario.length == 10) {
            setTelefoneUsuario(mascaraTelefoneFixo(telefoneUsuario)) // Aplica a máscara de telefone fixo
        }
    }

    //carrega as listas do codigo de sistema
    function preencherListaCodSist() {
        const id_filial = getSessionData("DadosIdfilial")

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/preencher/lista/codsist/${id_filial}`, {
            headers: {
                Authorization: token // Adiciona o token de autorização no cabeçalho da requisição
            }
        }).then(function (resposta) {
            setListaCodSist(resposta.data.dataListaCodSist)
        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    function buscarDetalhesForm() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/buscar/detalhes/form/${sistemaSelecionado}/${categoriaSelecionada}`, {
            headers: { Authorization: token } // Adiciona o token de autorização no cabeçalho da requisição
        }).then(function (resposta) {
            setDadosDetalhes(resposta.data.data)
        }).catch(function (erro) {
            // Trata erros de autorização e exibe uma mensagem para o usuário
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
            }
        })
    }

    useEffect(() => {
        if (sistemaSelecionado !== '') {

        }
    }, [sistemaSelecionado])

    useEffect(function () {
        if (categoriaSelecionada !== '' && sistemaSelecionado !== '') {
            buscarDetalhesForm()
        } else {
            setDadosDetalhes([])
        }
    }, [categoriaSelecionada, sistemaSelecionado])

    return (
        <>
            <div className="col">
                <ContainerTitulo titulo="Criação de Ticket" />
                <form onSubmit={enviarDados}>
                    <Row>
                        <Col sm={12}>
                            <Card className="p-4">
                                <Card className="mb-4 p-3 border shadow">
                                    <Row>
                                        <Col sm={4} className="mb-3">
                                            <div className="form-floating">
                                                <InputSemBorda
                                                    label="Seu nome"
                                                    name="nomeUsuario"
                                                    type="text"
                                                    readonly
                                                    placeholder="Nome"
                                                    value={nomeUsuario}
                                                    onChange={setNomeUsuario}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={4} className="mb-3">
                                            <div className="form-floating">
                                                <InputSemBorda
                                                    label="E-mail"
                                                    name="emailUsuario"
                                                    readonly
                                                    type="text"
                                                    placeholder="E-mail"
                                                    value={emailUsuario}
                                                    onChange={setEmailUsuario}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={4} className="mb-3">
                                            <div className="form-floating">
                                                <InputSemBorda
                                                    label="Telefone para contato"
                                                    name="telefoneUsuario"
                                                    required
                                                    type="text"
                                                    readonly={controleReadonlyTelefone}
                                                    placeholder="Telefone"
                                                    value={telefoneUsuario}
                                                    onChange={setTelefoneUsuario}
                                                    onBlur={mascararTelefones}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4} className="mb-3">
                                            <div className="form-floating">
                                                <InputSemBorda
                                                    label="Empresa"
                                                    name="empresaUsuario"
                                                    type="text"
                                                    readonly
                                                    placeholder="Empresa"
                                                    value={empresa}
                                                    onChange={setEmpresa}
                                                    classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={4} className="mb-3">
                                            <div className="col-16 form-floating">
                                                <select
                                                    autoFocus
                                                    required
                                                    className="form-select bordasInferiorInput text-black text-capitalize"
                                                    onChange={(event) => setSistemaSelecionado(event.target.value)}
                                                    value={sistemaSelecionado}
                                                >
                                                    <option value={""}>Selecione...</option>
                                                    {sistema.map((item: any) => (
                                                        <option value={item.descricao}>{item.descricao}</option>
                                                    ))}
                                                </select>
                                                <label className="form-label">Sistema Desejado</label>
                                            </div>
                                        </Col>
                                        <Col sm={4} className="mb-3">
                                            <div className="col-16 form-floating">
                                                <select
                                                    autoFocus
                                                    required
                                                    className="form-select bordasInferiorInput text-black text-capitalize"
                                                    onChange={(event) => setCategoriaSelecionada(event.target.value)}
                                                    value={categoriaSelecionada}
                                                >
                                                    <option value={""}>Selecione...</option>
                                                    {categoria.map((item: any) => (
                                                        <option value={item.descricao}>{item.descricao}</option>
                                                    ))}
                                                </select>
                                                <label className="form-label">Categoria</label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {dadosDetalhes.map((campo: any) => {
                                            const tabela: any = listaCodSist[campo.tabela]
                                            return (
                                                <>
                                                    {campo.tipo_campo === '3' && (
                                                        <Col sm={2} key={campo.id_det}>
                                                            <div className="form-floating">
                                                                <select
                                                                    autoFocus
                                                                    required={campo.obrigatorio}
                                                                    className="form-select bordasInferiorInput text-black text-capitalize"
                                                                    id={campo.id_campo}
                                                                >
                                                                    <option value={""}>Selecione...</option>
                                                                    {
                                                                        tabela.map(function (option: any) {
                                                                            return <option value={option.descricao}>{option.descricao}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                                <label id={'label' + campo.id_campo} htmlFor={campo.id_campo}>{campo.label_campo}</label>
                                                            </div>
                                                        </Col>
                                                    )}

                                                    {campo.tipo_campo === '2' && (
                                                        <Col sm={2} key={campo.id_det}>
                                                            <div className="form-floating">
                                                                <input
                                                                    id={campo.id_campo}
                                                                    required={campo.obrigatorio}
                                                                    className="form-control bordasInferiorInput text-black text-capitalize"
                                                                    placeholder=" "
                                                                    type="number"
                                                                />
                                                                <label id={'label' + campo.id_campo} htmlFor={campo.id_campo}>{campo.label_campo}</label>
                                                            </div>
                                                        </Col>
                                                    )}

                                                    {campo.tipo_campo === '1' && (
                                                        <Col sm={2} key={campo.id_det}>
                                                            <div className="form-floating">
                                                                <input
                                                                    id={campo.id_campo}
                                                                    required={campo.obrigatorio}
                                                                    className="form-control bordasInferiorInput text-black text-capitalize"
                                                                    placeholder=" "
                                                                    type="text"
                                                                />
                                                                <label id={'label' + campo.id_campo} htmlFor={campo.id_campo}>{campo.label_campo}</label>
                                                            </div>
                                                        </Col>
                                                    )}
                                                </>
                                            );
                                        })}
                                    </Row>
                                </Card>
                                <Card className="p-3 border shadow">
                                    <Row>
                                        <div className="container mt-2">
                                            <form>
                                                <div className="form-group">
                                                    <div className="d-flex flex-column mb-3">
                                                        <div className="mb-3 w-50">
                                                            <InputSemBorda
                                                                label="Título do Ticket"
                                                                name="ticket"
                                                                type="text"
                                                                required
                                                                placeholder="Título do Ticket"
                                                                value={tituloTicket}
                                                                onChange={setTituloTicket}
                                                                classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="formDescricaoChamado">Descrição do Chamado</label>
                                                            <ReactQuill
                                                                theme="snow"
                                                                value={descricaoTicket}
                                                                onChange={setDescricaoTicket}
                                                                style={{ height: '200px', width: '100%' }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <form>
                                                <div className="form-group mt-2 w-50">
                                                    <label htmlFor="fileInput">Inserir um anexo</label>
                                                    <input
                                                        multiple
                                                        type="file"
                                                        className="form-control-file"
                                                        id="fileInput"
                                                        onChange={converterAnexo}
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                    </Row>
                                </Card>
                                <div className="mt-3 d-flex justify-content-end mb-3">
                                    <Button
                                        invisivel={false}
                                        type="submit"
                                        className="btn btn-light text-info-emphasis bg-white border-0"
                                        descricaoBotao="Enviar"
                                        icone={mdiSendCircle}
                                        onclick={function () {
                                        }}
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </form>
                <ModalLoading show={loading} label="Carregando..." />
            </div>
        </>
    )
}

export default CriarTicket