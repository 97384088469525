import { Row, Col, Card } from "react-bootstrap";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import { InterfaceUsuario } from "../../../types/UsuarioInterface";
import { toast } from "react-toastify";
import ApiGet from "../../../api/endPoints/useGet";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import ApiDelete from "../../../api/endPoints/useDelete";
import { getSessionData } from "../../../utils/storageUtils";
import ClickableTable from "../../../components/ClickableTable/ClickableTabl";
export function Usuario() {
  const token = getSessionData("MultfilialWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  let isVinculo = getSessionData("DadosIdfilial") || "";
  const id_perfil = getSessionData("id_perfil")
  const id_filial = getSessionData("DadosIdfilial")
  const navigate = useNavigate();

  interface Action {
    icon: JSX.Element;
    name: string;
  }
  const actions: Action[] = [
    {
      icon: (
        <AddIcon
          onClick={() => navigate("/administrativo/usuario/usuario/novo")}
        />
      ),
      name: "Incluir",
    },
    // { icon: <FileCopyIcon />, name: "Duplicar" },
    // { icon: <SaveIcon />, name: "Salvar" },
    // { icon: <PrintIcon />, name: "Imprimir" },
  ];

  const visualiarUsuario = (dados: any) => {
    const id_usuario = dados.id_usuario;
    navigate("/administrativo/usuario/usuario/visu/" + id_usuario);
  };

  const editarUsuario = (dados: any) => {
    const id_usuario = dados.id_usuario;
    navigate("/administrativo/usuario/usuario/editar/" + id_usuario);
  };

  const excluirUsuario = async (dados: any) => {
    const id_usuario = dados.id_usuario;
    await showConfirmationDialog(
      "Excluir Usuário",
      `Tem certeza que deseja excluir o usuário \n ${dados.nome}?`,
      "question"
    )
      .then((result) => {
        if (result.confirmed) {
          const headers = {
            Authorization: token,
          };
          const dados = {
            url: `/usuario/excluir/?id_usuario=${id_usuario}&vinculo=${isVinculo}`,
            parametros: {},
            headers,
          };
          console.log("dados: ", dados);
          ApiDelete(dados)
            .then((retorno) => {
              toast.success(retorno.message);
              buscarDados(isSuper);
            })
            .catch((erro) => {
              toast.error(
                `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
              );
            });
        }
      })
      .catch((erro) => {
        toast.error(
          `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
        );
      });
  };

  const colunas: ITabela[] = [
    { titulo: "", acesso: "avatar" },
    { titulo: "Código", acesso: "id_usuario" },
    { titulo: "Nome", acesso: "nome" },
    { titulo: "Usuário", acesso: "nome_usuario" },
    { titulo: "Email", acesso: "email" },
    { titulo: "Telefone", acesso: "telefone" },
    { titulo: "Perfil", acesso: "nome_perfil" },
    { titulo: "Situação", acesso: "situacao" },
  ];

  //#region REALIZA REQUISIÇÃO PARA LISTAR DADOS DA TABELA
  const [dados, setDados] = useState<InterfaceUsuario[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const buscarDados = async (master: string) => {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: `/usuario/listar/?master=${master}&vinculo=${isVinculo || "0"}`,
      parametros: {

      },
      headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setDados(retorno);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        toast.error(`Erro ao listar usuários. Motivo: ${erro.message}`);
      });
  };

  //verifica permissão --- inicio
  const [permissao_visualizar, set_permissao_visualizar] = useState(true)
  const [permissao_incluir, set_permissao_incluir] = useState(true)
  const [permissao_editar, set_permissao_editar] = useState(true)
  const [permissao_excluir, set_permissao_excluir] = useState(true)


  async function CarregarPermissões(id_perfil: any, id_filial: any) {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/perfil/carregar/:idperfil/:idfilial",
      parametros: {
        idperfil: id_perfil,
        idfilial: id_filial
      },
      headers: headers,
    };
    ApiGet(dados).then(function (response) {
      if (response[0].visualizar_usuario === 1) {
        set_permissao_visualizar(true)
      }
      if (response[0].incluir_usuario === 1) {
        set_permissao_incluir(false)
      }
      if (response[0].editar_usuario === 1) {
        set_permissao_editar(true)
      }
      if (response[0].excluir_usuario === 1) {
        set_permissao_excluir(true)
      }


    })
  }
  //verifica permissão --- fim
  useEffect(() => {
    CarregarPermissões(id_perfil, id_filial)
    buscarDados(isSuper);
  }, []);

  //#endregion

  return (
    <>
      <div className="col mt-2">
        <ContainerTitulo titulo="Listagem de Usuários" />

        <Row>
          <Col sm={12}>
            <Card>
              <Card.Body>
                <Row>
                  <SpeedDial
                    hidden={permissao_incluir}
                    ariaLabel="SpeedDial basic example"
                    direction="left"
                    sx={{
                      right: 0,
                      position: "absolute",
                    }}
                    icon={<SpeedDialIcon />}
                  >
                    {actions.map((action) => (
                      <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                      />
                    ))}
                  </SpeedDial>
                </Row>
                <Row>
                  <ClickableTable
                    coluna={colunas}
                    data={dados}
                    usaRowClick={false}
                    onRowClick={function () {

                    }}
                    itemsPerPage={5}
                    acaoVisualizar={visualiarUsuario}
                    acaoEditar={editarUsuario}
                    acaoExcluir={excluirUsuario}
                    usaAcoes={true}
                    usaEditar={permissao_editar}
                    usaVisualizar={permissao_visualizar}
                    usaExcluir={permissao_excluir}
                    labelpesquisa="Procurar por Nome"
                    acessopesquisa="nome"
                  />
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <ModalLoading show={loading} label="Carregando Listagem de Usuários" />
    </>
  );
}
