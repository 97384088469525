import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './Pages/login/login';
import { MainPage } from './Pages/mainPage';
import { Usuario } from './Pages/administrativo/usuario/usuario';
import { UsuarioIncluir } from './Pages/administrativo/usuario/usuarioIncluir';
import { UsuarioEditar } from './Pages/administrativo/usuario/usuarioEditar';
import { UsuarioVisualizar } from './Pages/administrativo/usuario/usuarioVisualizar';
import { Permissao } from './Pages/administrativo/permissao/permissao';
import { CodigiodoSistema } from './Pages/administrativo/codigodoSistema/codigodosistema';
import { Empresa } from './Pages/administrativo/empresa/empresa';
import { PermissaoIncluir } from './Pages/administrativo/permissao/permissaoIncluir';
import { PermissaoVisualizar } from './Pages/administrativo/permissao/permissaoVisualizar';
import { PermissaoEditar } from './Pages/administrativo/permissao/permissaoEditar';
import { EmpresaIncluir } from './Pages/administrativo/empresa/empresaIncluir';
import { EmpresaEditar } from './Pages/administrativo/empresa/empresaEditar';
import { EmpresaVisualizar } from './Pages/administrativo/empresa/empresaVisualizar';
import { CodigiodoSistemaIncluir } from './Pages/administrativo/codigodoSistema/codigodosistemaIncluir';
import { CodigiodoSistemaVisualizar } from './Pages/administrativo/codigodoSistema/codigodosistemaVisualizar';
import { CodigiodoSistemaEditar } from './Pages/administrativo/codigodoSistema/codigodosistemaEditar';
import { Filiais } from './Pages/cadastro/filiais/filiais/filiais';
import { FilialIncluir } from './Pages/cadastro/filiais/filiais/filialIncluir';
import { FilialEditar } from './Pages/cadastro/filiais/filiais/filialEditar';
import { FilialVisualizar } from './Pages/cadastro/filiais/filiais/filialVisualizar';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StyledEngineProvider } from "@mui/material/styles";
import ListagemBots from './Pages/cadastro/bots/listagemBot';
import FormularioBots from './Pages/cadastro/bots/formulario';
import CriarTicket from './Pages/tickets/criacao/criacaoTicket';
import VisualizarTickets from './Pages/tickets/visualizacao/visualizacaoTicketsUsuario';
import AdministrarTickets from './Pages/tickets/controleTickets/administracaoTickets';
import DetalhesTicket from './Pages/tickets/controleTickets/detalhesTicket';
import DetalhesTicketUsuario from './Pages/tickets/visualizacao/detalhesTicketUsuario';
import Formularios from './Pages/administrativo/formularios/formularios';
import FormulariosIncluir from './Pages/administrativo/formularios/formulariosIncluir';
import FormulariosVisualizar from './Pages/administrativo/formularios/formulariosVisualizar';
import FormulariosEditar from './Pages/administrativo/formularios/formulariosEditar';
import Clientes from './Pages/cadastro/clientes/clientes';
import ClientesIncluir from './Pages/cadastro/clientes/clientesIncluir';
import ClientesVisualizar from './Pages/cadastro/clientes/clientesVisualizar';
import ClientesEditar from './Pages/cadastro/clientes/clientesEditar';
import Agentes from './Pages/configuracaoAgentes/agentes';
import AgentesEditar from './Pages/configuracaoAgentes/agentesEditar';

function App() {
  return (

    <div className="App">
      <div className="wrapper">
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <ToastContainer
              position="top-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
              theme="light"
            />
            <Routes>
              <Route path="/" element={<MainPage />}>

                {/* Administrativo -> Usuário */}
                <Route path="administrativo/usuario" element={<Usuario />} />
                <Route path="administrativo/usuario/usuario/:id" element={<UsuarioIncluir />} />
                <Route path="administrativo/usuario/usuario/visu/:id" element={<UsuarioVisualizar />} />
                <Route path="administrativo/usuario/usuario/editar/:id" element={<UsuarioEditar />} />

                {/* Administrativo -> Permissão */}
                <Route path="administrativo/permisao" element={<Permissao />} />
                <Route path="administrativo/permisao/permissao/:id" element={<PermissaoIncluir />} />
                <Route path="administrativo/permisao/permissao/visu/:id" element={<PermissaoVisualizar />} />
                <Route path="administrativo/permisao/permissao/editar/:id" element={<PermissaoEditar />} />

                {/* Administrativo -> Empresa */}
                <Route path="administrativo/empresa" element={<Empresa />} />
                <Route path="administrativo/empresa/empresa/:id" element={<EmpresaIncluir />} />
                <Route path="administrativo/empresa/empresa/visu/:id" element={<EmpresaVisualizar />} />
                <Route path="administrativo/empresa/empresa/editar/:id" element={<EmpresaEditar />} />

                {/* Administrativo -> Códigos do Sistema */}
                <Route path="administrativo/codistema" element={<CodigiodoSistema />} />
                <Route path="administrativo/codistema/codsis/:id" element={<CodigiodoSistemaIncluir />} />
                <Route path="administrativo/codistema/codsis/visu/:id" element={<CodigiodoSistemaVisualizar />} />
                <Route path="administrativo/codistema/codsis/editar/:id" element={<CodigiodoSistemaEditar />} />

                {/*Administrativo -> Cadastro -> Filial */}
                <Route path="cadastro/filiais/filial" element={<Filiais />} />
                <Route path="cadastro/filiais/filials/:id" element={<FilialIncluir />} />
                <Route path="cadastro/filiais/filial/:id" element={<FilialIncluir />} />
                <Route path="cadastro/filiais/filial/visu/:id" element={<FilialVisualizar />} />
                <Route path="cadastro/filiais/filial/editar/:id" element={<FilialEditar />} />

                {/*Administrativo -> Formulários */}
                <Route path="administrativo/formularios/formularios" element={<Formularios />} />
                <Route path="administrativo/formularios/formularios/formulariosIncluir" element={<FormulariosIncluir />} />
                <Route path="administrativo/formularios/formularios/formulariosVisualizar/:idFormulario" element={<FormulariosVisualizar />} />
                <Route path="administrativo/formularios/formularios/formulariosEditar/:idFormulario" element={<FormulariosEditar />} />

                {/*cadastro-> bot */}
                <Route path="cadastro/bots/filial/listagem" element={<ListagemBots />} />
                <Route path="cadastro/formulario/bots/filial/:id_bot/:acao" element={<FormularioBots />} />

                {/*cadastro-> clientes */}
                <Route path="cadastro/clientes/clientes" element={<Clientes />} />
                <Route path="/cadastro/clientes/clientes/:id" element={<ClientesIncluir />} />
                <Route path="/cadastro/clientes/clientes/visualizar/:id" element={<ClientesVisualizar />} />
                <Route path="/cadastro/clientes/clientes/editar/:id" element={<ClientesEditar />} />

                {/*tickets */}
                <Route path="tickets/criacao/" element={<CriarTicket />} />
                <Route path="tickets/visualizacao/" element={<VisualizarTickets />} />
                <Route path="tickets/visualizacao/detalhesTicketUsuario/:id_ticket" element={<DetalhesTicketUsuario />} />

                {/*administracao tickets */}
                <Route path="tickets/controleTickets/" element={<AdministrarTickets />} />
                <Route path="tickets/controleTickets/detalhesTicket/:id_ticket" element={<DetalhesTicket />} />

                {/*configuracao de agentes */}
                <Route path="configuracaoAgentes/agentes/" element={<Agentes />} />
                <Route path="configuracaoAgentes/agentes/agentesEditar/:id" element={<AgentesEditar />} />
              </Route>

              <Route path="/login" element={<Login />} />
            </Routes>
          </StyledEngineProvider>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
